import { useNavigate } from '@remix-run/react'
import clsx from 'clsx'
import { type ReactNode } from 'react'
import { Anchor } from '~/components/ui/link'
import { ArrowLeft } from '~/icons/arrows'

export type PageHeaderProps = {
	title: ReactNode
	actions?: ReactNode | ReactNode[]
}

export type AppHeaderProps = PageHeaderProps & {
	tag?: ReactNode
	tabs?: ReactNode
	previousLocation?: string
}

export type WebHeaderProps = PageHeaderProps & {
	breadcrumb?: ReactNode
	description?: ReactNode
	actions?: ReactNode
	centered?: boolean
}

export default function AppHeader({
	title,
	tag,
	tabs,
	actions,
	previousLocation
}: AppHeaderProps) {
	const navigate = useNavigate()
	return (
		<header
			className={clsx(
				'z-page-header flex max-h-[10rem] flex-none flex-col items-start gap-0'
			)}
		>
			<div className='inline-flex max-h-12 min-h-12 flex-col gap-[10px] px-4 py-[13px]'>
				{!!previousLocation && (
					<Anchor
						$size='primary'
						href={previousLocation}
						className='flex items-center gap-2'
					>
						<ArrowLeft className='text-[inherit]' />
						Back to Workspace
					</Anchor>
				)}
			</div>
			<div className='relative flex h-16 w-full flex-none items-start justify-between px-4'>
				<h1 className='text-h3 font-light'>{title}</h1>
				{actions && (
					<div className='flex items-center gap-4'>{actions}</div>
				)}
			</div>
			{(tabs || tag) && (
				<div className='flex h-12 w-full items-stretch text-body'>
					<div className='flex h-full max-w-full shrink items-stretch'>
						{tabs}
					</div>
					{tag && (
						<div className='border-1 flex h-full w-full grow items-center justify-end border-b border-solid border-border-subtle-00 pr-4'>
							{tag}
						</div>
					)}
				</div>
			)}
		</header>
	)
}

export function WebHeader({
	title,
	description,
	breadcrumb,
	actions,
	centered
}: WebHeaderProps) {
	return (
		<header
			className={clsx(
				'z-base flex flex-none flex-col gap-0',
				centered ? 'items-center' : 'items-start'
			)}
		>
			<div className='flex max-h-12 min-h-12 flex-col gap-[10px] px-4 py-[13px]'>
				{breadcrumb}
			</div>
			<div
				className={clsx(
					'flex flex-col justify-between gap-8 px-4 py-0',
					actions ? 'min-h-[640px]' : 'min-h-[320px]',
					centered && '!min-h-0 !gap-20'
				)}
			>
				<h1
					className={clsx(
						'max-w-3xl text-pretty text-h1 font-light',
						centered && '!max-w-[640px]'
					)}
				>
					{title}
				</h1>
				<div
					className={clsx(
						'flex h-full max-w-[480px] flex-col gap-8',
						!actions && 'hidden',
						centered && '!max-w-[640px]'
					)}
				>
					<h2 className='font-regular max-w-xl text-pretty text-h5'>
						{description}
					</h2>
					<div className='flex flex-row gap-4'>{actions}</div>
				</div>
			</div>
		</header>
	)
}
