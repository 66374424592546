export function ArrowLeft({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_2751_111868)'>
				<path
					d='M14 26L15.41 24.59L7.83 17H28V15H7.83L15.41 7.41L14 6L4 16L14 26Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_2751_111868'>
					<rect
						width='32'
						height='32'
						style={{
							fill: 'currentColor',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export function ArrowRight({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_2751_111869)'>
				<path
					d='M18 6L16.57 7.393L24.15 15H4V17H24.15L16.57 24.573L18 26L28 16L18 6Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_2751_111869'>
					<rect
						width='32'
						height='32'
						style={{
							fill: 'currentColor',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export function ArrowUp({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_532_12866)'>
				<path
					d='M16 4L6 14L7.41 15.41L15 7.83V28H17V7.83L24.59 15.41L26 14L16 4Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_532_12866'>
					<rect
						width='32'
						height='32'
						style={{
							fill: 'currentColor',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export function ArrowDown({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_532_12863)'>
				<path
					d='M24.59 16.59L17 24.17V4H15V24.17L7.41 16.59L6 18L16 28L26 18L24.59 16.59Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_532_12863'>
					<rect
						width='32'
						height='32'
						style={{
							fill: 'currentColor',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export function ArrowsVertical({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_532_12869)'>
				<path
					d='M27.6 20.6L24 24.2V4H22V24.2L18.4 20.6L17 22L23 28L29 22L27.6 20.6Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
				<path
					d='M9 4L3 10L4.4 11.4L8 7.8V28H10V7.8L13.6 11.4L15 10L9 4Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_532_12869'>
					<rect
						width='32'
						height='32'
						style={{
							fill: 'currentColor',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
